import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { videosv2 } from "../../videos";
import { ReactComponent as Logo } from "../../../img/glo-logo.svg";
import "./style.scss";

export default function VideoPage() {
  const { pathname } = useLocation();
  const video = videosv2.filter(
    (e) =>
      e.ident ===
      pathname.replace("/hyperx2/", "").replace(/\//g, "").trim().toLowerCase()
  )[0];

  const [isEnded, setIsEnded] = useState(false);
  const [isPaused, setIsPaused] = useState(true);

  return (
    <div className="main-wrapper">
      <section className="video-pagev2">
        <div className="container">
          <Logo className="logo" />
          <h2>Nowoczesna technologia i&nbsp;prawdziwy tytoń</h2>
          <p>Obejrzyj film i poznaj nasze urządzenia</p>
          <div
            className={`video-holder${isEnded ? " ended" : ""}${
              isPaused ? " paused" : ""
            }`}
          >
            <video
              preload="metadata"
              controls
              controlsList="nodownload"
              src={video?.video}
              onEnded={() => {
                setIsEnded(true);
              }}
              onPlay={() => {
                setIsEnded(false);
                setIsPaused(false);
              }}
              onPause={() => {
                setIsEnded(false);
                setIsPaused(true);
              }}
            ></video>
            <div className="re-play-icon"></div>
          </div>
          <p className="disclaimer">Niepubliczna informacja konsumencka</p>
        </div>
      </section>
    </div>
  );
}
