import React from "react";
import { ReactComponent as Logo } from "../../../img/glo-logo.svg";
import "../VideoPagev2/style.scss";

export default function LoadingPagev2() {
  return (
    <div className="main-wrapper">
      <section className="video-pagev2">
        <div className="container">
          <Logo className="logo" />
          <h2>Nowoczesna technologia i&nbsp;prawdziwy tytoń</h2>
          <p>Obejrzyj film i poznaj nasze urządzenia</p>
          <div className="placeholder">
            <h3>Trwa ładowanie...</h3>
          </div>
          <p className="disclaimer">Niepubliczna informacja konsumencka</p>
        </div>
      </section>
    </div>
  );
}
