import React from "react";
import { ReactComponent as Logo } from "../../../img/glo-logo.svg";
import "../VideoPage/style.scss";
import bg from "../../../img/bg-mobilev3.png";
export default function WrongIdentPagev3() {
  return (
    <section className="video-page">
      <div className="container">
        <div className="content-container">
          <Logo className="logo" />
          <img className="glo-devices" src={bg} alt="glo hyper" />
          <div className="content">
            <h2>glo™ hyper AIR</h2>
            <div className="description">
              <p>Obejrzyj film i poznaj nasze urządzenia</p>
            </div>
          </div>
        </div>
        <div className="placeholder">
          <h3>Film nie istnieje</h3>
          <p>Prawdopodobnie korzystasz z adresu, który jest już nieaktualny.</p>
          <div className="button-wrapper">
            <a href="https://inspirationclub.pl/" className="button">
              Przejdź do Inspiration Club
            </a>
          </div>
        </div>
        <p className="disclaimer">Niepubliczna informacja konsumencka</p>
      </div>
    </section>
  );
}
