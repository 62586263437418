import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { videosv3 } from "../../videos";
import { ReactComponent as Logo } from "../../../img/glo-logo.svg";
import "./style.scss";
import bg from "../../../img/bg-mobilev3.png";

export default function VideoPage() {
  const { pathname } = useLocation();
  const video = videosv3.filter(
    (e) =>
      e.ident ===
      pathname.replace("/gloair/", "").replace(/\//g, "").trim().toLowerCase()
  )[0];
  const [isEnded, setIsEnded] = useState(false);
  const [isPaused, setIsPaused] = useState(true);

  return (
    <section className="video-page">
      <div className="container">
        <div className="content-container">
          <Logo className="logo" />
          <img className="glo-devices" src={bg} alt="glo hyper" />
          <div className="content">
            <h2>glo™ hyper AIR</h2>
            <div className="description">
              <p>
                Obejrzyj film <span>i poznaj nasze urządzenia</span>
              </p>
            </div>
          </div>
        </div>
        <div
          className={`video-holder${isEnded ? " ended" : ""}${
            isPaused ? " paused" : ""
          }`}
        >
          <video
            preload="metadata"
            controls
            controlsList="nodownload"
            poster={video?.placehoder}
            src={video?.video}
            onEnded={() => {
              setIsEnded(true);
            }}
            onPlay={() => {
              setIsEnded(false);
              setIsPaused(false);
            }}
            onPause={() => {
              setIsEnded(false);
              setIsPaused(true);
            }}
          ></video>
          <div className="re-play-icon"></div>
        </div>

        <p className="disclaimer">Niepubliczna informacja konsumencka</p>
      </div>
    </section>
  );
}
