import 'fetch-polyfill'
import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./styles/index.scss";
import MainView from "./app/MainView";
import { UserProvider } from './app/UserContext';
import { initUrlParams } from './app/UrlParamsHelper';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const checkUrlParams = () => {
  // replace '&' with '?' in search params
  const includesAmp = window.location.href.includes("&")
  const includesQuestion = window.location.href.includes("?")
  if(includesAmp && !includesQuestion) window.location.href = window.location.href.replace("&", "?")
  
  // get parsed URL params, before app init
  initUrlParams()
}

checkUrlParams();

ReactDOM.render(
  <>
    <UserProvider>
        <MainView />
    </UserProvider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
