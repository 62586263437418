import { getUrlParam } from "../UrlParamsHelper";

export async function checkToken(dispatch) {
  try {
    dispatch({ type: "CHECK_TOKEN" });

    let response = await fetch(
      process.env.REACT_APP_API_URL + "/campaign/check",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getUrlParam("token"),
        },
      }
    );

    let res = await response.json();

    if (res.status.success) {
      dispatch({ type: "CHECK_TOKEN_SUCCESS", payload: res.data });
      return res;
    }

    dispatch({ type: "CHECK_TOKEN_ERROR", error: res.data.error });
    return res;
  } catch (error) {
    dispatch({ type: "CHECK_TOKEN_ERROR", error: error });
  }
}
