import React from "react";
import { ReactComponent as Logo } from "../../../img/glo-logo.svg";
import "../VideoPage/style.scss";
import bg from "../../../img/bg-mobile.png";
export default function WrongIdentPage() {
  return (
    <section className="video-page">
      <div className="container">
        <div className="content-container">
          <Logo className="logo" />
          <img className="glo-devices" src={bg} alt="glo hyper" />
          <div className="content">
            <h2>glo™ hyper pro</h2>
            <div className="description">
              <p>
                Obejrzyj film <span>i poznaj nasze urządzenia</span>
              </p>
            </div>
          </div>
        </div>
        <div className="placeholder">
          <h3>Błąd autoryzacji</h3>
          <p>
            Coś poszło nie tak. Twój token sesji wygasł lub nie jest już
            aktywny.
          </p>
        </div>
        <p className="disclaimer">Niepubliczna informacja konsumencka</p>
      </div>
    </section>
  );
}
