import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedComponent";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import HealthWarning from "./components/HealthWarning";
import VideoPage from "./pages/VideoPage";
import VideoPagev2 from "./pages/VideoPagev2";
import VideoPagev3 from "./pages/VideoPagev3";
import WrongIdentPage from "./pages/WrongIdentPage";
import WrongIdentPagev3 from "./pages/WrongIdentPagev3";
import WrongIdentPagev2 from "./pages/WrongIdentPagev2";
import LoadingPage from "./pages/LoadingPage";
import LoadingPagev2 from "./pages/LoadingPagev2";
import LoadingPagev3 from "./pages/LoadingPagev3";
import { videos, videosv2, videosv3 } from "./videos";
import { useUserState, checkToken, useUserDispatch } from "./UserContext";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".app").scrollTo(0, 0);
    }, 250);
  }, [pathname]);

  return null;
}

const renderLoadingPage = (location) => {
  const itsHyperx2 = location?.pathname?.includes("/hyperx2");
  const itsGloAir = location?.pathname?.includes("/gloair");
  if (itsHyperx2) {
    return <LoadingPagev2 />;
  }
  if (itsGloAir) {
    return <LoadingPagev3 />;
  }
  return <LoadingPage />;
};

function Transition() {
  let location = useLocation();
  const dispatch = useUserDispatch();
  const { token, loading } = useUserState();

  useEffect(() => {
    if (!token?.campaign) {
      checkToken(dispatch);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div className="app">
      <div className="page-holder">
        <HealthWarning />
        {loading ? (
          renderLoadingPage(location)
        ) : (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="page"
              className="page-with-bg"
              timeout={{ enter: 250, exit: 250 }}
            >
              <div>
                <Routes location={location}>
                  {videos.map((video, i) => (
                    <Route
                      key={i}
                      path={`${process.env.PUBLIC_URL}/${video.ident}`}
                      exact
                      element={
                        <ProtectedRoute>
                          <VideoPage />
                        </ProtectedRoute>
                      }
                    />
                  ))}
                  {videosv2.map((video, i) => (
                    <Route
                      key={i}
                      path={`${process.env.PUBLIC_URL}/hyperx2/${video.ident}`}
                      exact
                      element={
                        <ProtectedRoute>
                          <VideoPagev2 />
                        </ProtectedRoute>
                      }
                    />
                  ))}
                  {videosv3.map((video, i) => (
                    <Route
                      key={i}
                      path={`${process.env.PUBLIC_URL}/gloair/${video.ident}`}
                      exact
                      element={
                        <ProtectedRoute>
                          <VideoPagev3 />
                        </ProtectedRoute>
                      }
                    />
                  ))}
                  <Route
                    path={`${process.env.PUBLIC_URL}/*`}
                    exact
                    element={<WrongIdentPage />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/hyperx2/*`}
                    exact
                    element={<WrongIdentPagev2 />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/gloair/*`}
                    exact
                    element={<WrongIdentPagev3 />}
                  />
                </Routes>
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
      </div>
    </div>
  );
}

export default function MainView() {
  return (
    <Router>
      <ScrollToTop />
      <Transition />
    </Router>
  );
}
