import React from "react";
import { ReactComponent as Logo } from "../../../img/glo-logo.svg";
import "../VideoPagev2/style.scss";

export default function WrongIdentPagev2() {
  return (
    <div className="main-wrapper">
      <section className="video-pagev2">
        <div className="container">
          <Logo className="logo" />
          <h2>Nowoczesna technologia i&nbsp;prawdziwy tytoń</h2>
          <p>Obejrzyj film i poznaj nasze urządzenia</p>
          <div className="placeholder">
            <h3>Film nie istnieje</h3>
            <p>
              Prawdopodobnie korzystasz z adresu, który jest już nieaktualny.
            </p>

            <a href="https://inspirationclub.pl/" className="button">
              Przejdź do Inspiration Club &gt;
            </a>
          </div>
          <p className="disclaimer">Niepubliczna informacja konsumencka</p>
        </div>
      </section>
    </div>
  );
}
