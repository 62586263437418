import HOW_TO_USE from "../video/HOW_TO_USE.mp4";
import HOW_TO_CLEAN from "../video/HOW_TO_CLEAN.mp4";
import HOW_TO_CHARGE from "../video/HOW_TO_CHARGE.mp4";
import HOW_TO_USE_AIR from "../video/HOW_TO_USE_AIR.mp4";
import HOW_TO_CLEAN_AIR from "../video/HOW_TO_CLEAN_AIR.mp4";
import HOW_TO_CHARGE_AIR from "../video/HOW_TO_CHARGE_AIR.mp4";
import placeholderHowToUse from "../video/placeholder-how-to-use.jpg";
import placeholderHowToCharge from "../video/placeholder-how-to-charge.jpg";
import placeholderHowToClean from "../video/placeholder-how-to-clean.jpg";
import placeholderHowToUseAir from "../video/placeholder-how-to-use-air.jpg";
import placeholderHowToChargeAir from "../video/placeholder-how-to-charge-air.jpg";
import placeholderHowToCleanAir from "../video/placeholder-how-to-clean-air.jpg";

import X2_1 from "../video/X2_1.mp4";
import X2_2 from "../video/X2_2.mp4";
import X2_5 from "../video/X2_5.mp4";
import X2_6 from "../video/X2_6.mp4";
import X2_7 from "../video/X2_7.mp4";

export const videos = [
  {
    ident: "how_to_use",
    title: "how_to_use",
    video: HOW_TO_USE,
    placehoder: placeholderHowToUse,
  },
  {
    ident: "how_to_clean",
    title: "how_to_clean",
    video: HOW_TO_CLEAN,
    placehoder: placeholderHowToClean,
  },
  {
    ident: "how_to_charge",
    title: "how_to_charge",
    video: HOW_TO_CHARGE,
    placehoder: placeholderHowToCharge,
  },
];
export const videosv2 = [
  {
    ident: "x2_1",
    title: "x2_1",
    video: X2_1,
  },
  {
    ident: "x2_2",
    title: "x2_2",
    video: X2_2,
  },
  {
    ident: "x2_5",
    title: "x2_5",
    video: X2_5,
  },
  {
    ident: "x2_6",
    title: "x2_6",
    video: X2_6,
  },
  {
    ident: "x2_7",
    title: "x2_7",
    video: X2_7,
  },
];

export const videosv3 = [
  {
    ident: "how_to_use",
    title: "how_to_use",
    video: HOW_TO_USE_AIR,
    placehoder: placeholderHowToUseAir,
  },
  {
    ident: "how_to_clean",
    title: "how_to_clean",
    video: HOW_TO_CLEAN_AIR,
    placehoder: placeholderHowToCleanAir,
  },
  {
    ident: "how_to_charge",
    title: "how_to_charge",
    video: HOW_TO_CHARGE_AIR,
    placehoder: placeholderHowToChargeAir,
  },
];
