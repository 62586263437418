import React from "react";
import { useUserState } from "../UserContext";
import WrongTokenPage from "../pages/WrongTokenPage";
import WrongTokenPagev2 from "../pages/WrongTokenPagev2";
import WrongTokenPagev3 from "../pages/WrongTokenPagev3";
import { useLocation } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const userDetails = useUserState();
  const location = useLocation();

  const renderWrongTokenPage = (location) => {
    const itsHyperx2 = location?.pathname?.includes("/hyperx2");
    const itsGloAir = location?.pathname?.includes("/gloair");
    if (itsHyperx2) {
      return <WrongTokenPagev2 />;
    }
    if (itsGloAir) {
      return <WrongTokenPagev3 />;
    }
    return <WrongTokenPage />;
  };

  return !Boolean(userDetails.token?.campaign)
    ? renderWrongTokenPage(location)
    : children;
};

export const ProtectedComponent = ({ children }) => {
  const userDetails = useUserState();

  return !Boolean(userDetails.token?.campaign) ? null : children;
};
